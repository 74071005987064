import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../../../containers";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiurl, DAPI_URL } from "../../../_config";
import toast from "react-hot-toast";

const Newpassword = () => {
    const { isDarkMode } = useTheme();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [id, setId] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const storedId = localStorage.getItem('verificationId');
        if (storedId) {
            setId(storedId);
        } else {
            toast.error("No verification data found. Please start the process again.");
            navigate("/forgotpassword");
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.ChangePassword}`, {
                id: id,
                newpassword: newPassword,
                confirmpassword: confirmPassword
            });

            if (response.status === 200) {
                if (response.data.status === 1) {
                    toast.success("Password updated successfully");
                    navigate("/congratulation");
                } else {
                    toast.error(response.data.message || "Password update failed");
                }
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Please try again later.");
        }
    };

    return (
        <>
            <section className="same_width_page new_password">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/forgotpassword" className="forgot-password">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow"/>
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow"/>
                                    </Link>
                                    <span className="light_arow_text">New Password</span>
                                </div>
                                <div className="login_inner">
                                    <div className="logo">
                                        <img 
                                            src={`images/${isDarkMode ? 'New Password edit.png' : 'light-new-password.png'}`} 
                                            alt="" 
                                            width="120" 
                                            className={isDarkMode ? 'dark_forgot_img' : 'light_forgot_img'}
                                        />
                                    </div>
                                    <div className="forgot_content">
                                        <h2>New Password</h2>
                                        <p>Set the new password for your account so you can login and access all the features.</p>
                                        <div className="login_from mt-4">
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group from_chek">
                                                    <label htmlFor="newpassword">New Password</label>
                                                    <div className="password_wepper">
                                                        <input 
                                                            type={showPassword ? 'text' : 'password'}
                                                            name="newpassword"
                                                            id="newpassword"
                                                            className="form-control"
                                                            value={newPassword}
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            required
                                                        />
                                                        <img 
                                                            src={`images/${isDarkMode ? 'Get Started eye.png' : 'eye-light.png'}`} 
                                                            alt="" 
                                                            className="dark_eye"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group from_chek">
                                                    <label htmlFor="confirmpassword">Confirm Password</label>
                                                    <div className="password_wepper">
                                                        <input 
                                                            type={showConfirmPassword ? 'text' : 'password'}
                                                            name="confirmpassword"
                                                            id="confirmpassword"
                                                            className="form-control"
                                                            value={confirmPassword}
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            required
                                                        />
                                                        <img 
                                                            src={`images/${isDarkMode ? 'New Password eye-slash.png' : 'light-eye-slash.png'}`} 
                                                            alt="" 
                                                            className="dark_eye"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <button type="submit" className="login_btn text-white">Update Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Newpassword;
