// src/App.js
import './App.css';
import React, { useEffect } from 'react';
import { Routes, Route, HashRouter } from "react-router-dom";
 
import Splash from './components/auth/splash';
import Login from './components/auth/login';
import Main from './components/auth/main';
import ForgotPassword from './components/auth/forgotpassword';
import Verify from './components/auth/verifycode';
import Newpassword from './components/auth/newpassword';
import Congratulation from './components/auth/congratulation';
import ConfermMobile from './components/auth/confermMobile';
import CompleteProfile from './components/auth/completeProfile';
import NewCongratulation from './components/auth/newcongratulation';
import Home from './components/home';
import Profile from './components/profile';
import Editprofile from './components/editprofile';
import Setting from './components/settings';
import Logout from './components/logout';
import Contactus from './components/contact-support';
import Addqr from './components/addqrcode';
import Parkvehicle from './components/parkvehicle';
import Myqr from './components/myqrlist';
import EmergencyServices from './components/emergency-services';
 
import { AuthProvider } from './containers/AuthContext';
import ProtectedRoute from './containers/ProtectedRoute';
import QrDetail from './components/qrDetail';
import BarcodeScanner from './containers/BarcodeScanner';

function App() {
  useEffect(() => {
    clearCacheData();
}, []);

  const clearCacheData = async () => {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(name => caches.delete(name)));
    // alert("Complete Cache Cleared"); 
};

  return (
    <AuthProvider>
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="verifycode" element={<Verify />} />  
          <Route path="newpassword" element={<Newpassword />} /> 
          <Route path="congratulation" element={<Congratulation />} /> 
          <Route path="confermmobile" element={<ConfermMobile />} />
          <Route path="completeprofile" element={<CompleteProfile />} />
          <Route path="newcongrat" element={<NewCongratulation />} />
          <Route path="login" element={<Main />} />
          <Route path="qrdetail" element={<QrDetail />} />
          {/* <Route path="barcodeScanner" element={<BarcodeScanner />} /> */}
          
          
          {/* Protecting Routes */}
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} /> 
          <Route path="profile" element={<ProtectedRoute element={<Profile />} />} /> 
          <Route path="editprofile" element={<ProtectedRoute element={<Editprofile />} />} /> 
          <Route path="setting" element={<ProtectedRoute element={<Setting />} />} /> 
          <Route path="logout" element={<ProtectedRoute element={<Logout />} />} /> 
          <Route path="contactus" element={<ProtectedRoute element={<Contactus/>} />} /> 
          <Route path="addqr" element={<ProtectedRoute element={<Addqr/>} />} /> 
          <Route path="parkvehicle" element={<ProtectedRoute element={<Parkvehicle/>} />} /> 
          <Route path="myqr" element={<ProtectedRoute element={<Myqr/>} />} /> 
          <Route path="emergency-services" element={<ProtectedRoute element={<EmergencyServices/>} />} /> 
        </Routes>
      </HashRouter>
    </AuthProvider>
  );
}

export default App;
