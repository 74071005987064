 
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "../../containers";
import { useEffect, useState } from "react";

const QrDetail = () => {
    const { isDarkMode } = useTheme();
    const location = useLocation();
    const data = location.state; // Get data passed from Home component
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        // Function to get user's location
        const getUserLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ latitude, longitude });
                }, (error) => {
                    console.error("Error getting location:", error);
                });
            } else {
                console.error("Geolocation is not supported by this browser.");
            }
        };

        getUserLocation();
    }, []);

    const createWhatsAppShareLink = () => {
        if (userLocation) {
            const message = `Hey, I'm currently at: https://www.google.com/maps/@${userLocation.latitude},${userLocation.longitude},15z`;
            return `https://wa.me/${data?.emergencyContact}?text=${encodeURIComponent(message)}`;
        }
        return '#'; // Fallback if location is not available
    };


    return (
        <>
           <section class="same_width_page home1_page">
        <div class="container">
            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <div class="same_color_body">
                    <div class="scrre_bg">
                        <div class="screen_logo text-center">
                            <img src="images/logo.svg" alt="" class="dark_logo"/>
                            <img src="images/light-logo.svg" alt="" class="light_logo"/>
                        </div>
                        <div class="screen_detals_wepper">
                            <p>Vehicle No. <span>{data?.vehicleNumber}</span></p>
                            <p>Blood Group <span style={{color: "#CD030A"}} >{data?.bloodGroup || "N/A"}</span></p>
                            <p><span>{data?.city}, {data?.state}</span></p>
                        </div>
                        {/* <!-- <div class="shodow_bottom"></div> --> */}
                        <div class="scree_call_wepper">
                            <div class="scree_call_inneer">
                                <img src="images/screen_data.png" alt="" class="dark_arrow"/>
                                <img src="images/bg-light.png" alt="" class="light_arrow"/>
                                <Link to={`tel:${data?.mobile}`} >
                                <div class="call_icon_scren">
                                    <img src="images/call-calling-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/call-calling.png" alt="" class="light_arrow"/>
                                    <p>Call Owner</p>
                                </div>
                                </Link>
                            </div>
                            <div class="scree_call_inneer">
                                <img src="images/screen_data.png" alt="" class="dark_arrow"/>
                                <img src="images/bg-light.png" alt="" class="light_arrow"/>
                                
                                <div class="call_icon_scren">
                                    <img src="images/message-text-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/message-text.png" alt="" class="light_arrow"/>
                                    <p>Message Owner</p>
                                </div>
                                
                            </div>

                        </div>
                        <div class="scree_call_wepper pt-0">
                            <div class="scree_call_inneer">
                                <img src="images/screen_data.png" alt="" class="dark_arrow"/>
                                <img src="images/bg-light.png" alt="" class="light_arrow"/>
                                <Link to={`tel:${data?.emergencyContact}`}>
                                <div class="call_icon_scren">
                                    <img src="images/emergency-icon-dark.png" alt="" class="dark_arrow"/>
                                    <img src="images/Emergency1.png" alt="" class="light_arrow"/>
                                    <p>Emergency 1</p>
                                </div>
                                </Link>
                            </div>
                            <div class="scree_call_inneer">
                                <img src="images/screen_data.png" alt="" class="dark_arrow"/>
                                <img src="images/bg-light.png" alt="" class="light_arrow"/>
                                <Link to={`tel:${data?.emergencyContact2}`}>
                                <div class="call_icon_scren">
                                    <img src="images/emergency-icon-dark.png" alt="" class="dark_arrow"/>
                                    <img src="images/Emergency1.png" alt="" class="light_arrow"/>
                                    <p>Emergency 2</p>
                                </div>
                                </Link>
                            </div>
                        </div>

                        <div className="logout_btn">
                                    <Link to={createWhatsAppShareLink()}>
                                        <img src="images/locationscreen.png" alt="" className="dark_arrow" />
                                        <img src="images/location-light.png" alt="" className="light_arrow" />
                                        Share Location On WhatsApp
                                    </Link>
                                </div>

                        <div class="text-center dark_logo_footer">
                            <img src="images/logo_light.png" alt="" class="dark_arrow"/>
                            <img src="images/logo-light-thems.png" alt="" class="light_arrow"/>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section>

        </>
    );
};

export default QrDetail;
