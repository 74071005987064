import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '../../../containers';
import { apiurl, DAPI_URL } from '../../../_config';
import toast from 'react-hot-toast';
import { useAuth } from '../../../containers/AuthContext';
import QRCodeScanner from '../../../containers/QRCodeScanner';

const Login = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();
    const { login } = useAuth();

    const [registrationData, setRegistrationData] = useState({
        fullName: '',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
    });

    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isScannerOpen, setIsScannerOpen] = useState(false);

    const handleRegistrationChange = (e) => {
        const { name, value } = e.target;
        setRegistrationData({
            ...registrationData,
            [name]: value
        });
    };

    const handleLoginChange = (e) => {
        const { name, value } = e.target;
        if (name === "mobile") setMobile(value);
        else if (name === "password") setPassword(value);
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.Login}`, {
                mobile,
                password
            });

            if (response.status === 200) {
                if (response.data.status === 1) {
                    const userData = response.data.data;
                    localStorage.setItem('callMsgStatus', userData.callMsgStatus);
                    localStorage.setItem('locationShare', userData.locationshare);
                    localStorage.setItem('msgStatus', userData.msgStatus);
                    localStorage.setItem('womenSafety', userData.women_safety);
                    localStorage.setItem('userId', userData.id );

                    toast.success("User successfully logged in");
                    navigate("/home"); // Navigate to the home page or any other page
                    login();
                } else {
                    toast.error(response.data.message || "Login failed");
                }
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Please try again later.");
        }
    };

    const handleRegistrationSubmit = async (e) => {
        e.preventDefault();
        const { fullName, mobileNumber, email, password, confirmPassword } = registrationData;

        // Basic validation
        if (password !== confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.RegisterWithMobile}`, {
                fname: fullName,
                mobile: mobileNumber,
                email,
                password,
                confirmpassword: confirmPassword
            });

            if (response.status === 200) {
                if (response.data.status === 1) {
                    localStorage.setItem('registeredId', response.data.data.id);
                    toast.success("Successfully registered");
                    navigate("/confermmobile");
                } else {
                    toast.error(response.data.message || "Verification failed");
                }
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            // console.error('Error:', error);
            toast.error("Please try again later.");
        }
    };

    const handleScan = async (qucodeId) => {
        try {
            // Extract the code from the scanned URL
            const extractedCode = qucodeId.split('=')[1]; // Get the part after '='
            
            if (!extractedCode) {
                alert("Invalid QR code format.");
                return;
            }
    
            const response = await axios.post(`${apiurl}${DAPI_URL?.QrCodeDetails}`, { qucodeId: extractedCode });
            
            if (response.data.status === 1) {
                navigate("/QrDetail", { state: response.data.data });
            } else {
                alert("Invalid QR code.");
            }
        } catch (error) {
            console.error("Error fetching QR code details:", error);
        } finally {
            setIsScannerOpen(false);
        }
    };
    

    return (
        <>
            <section className="same_width_page login_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body register_wepper">
                            <div className="login_wepper">
                                <div className="login_inner">
                                    <div className="logo">
                                        <img src="images/logo.svg" alt="" className="dark_logo" />
                                        <img src="images/light-logo.svg" alt="" className="light_logo" />
                                    </div>
                                    <div className="login_tab_main">
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link active" id="login" data-bs-toggle="tab"
                                                    data-bs-target="#login-pane" type="button" role="tab"
                                                    aria-controls="login-pane" aria-selected="true">Login</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link" id="register" data-bs-toggle="tab"
                                                    data-bs-target="#register-pane" type="button" role="tab"
                                                    aria-controls="register-pane" aria-selected="false">Register</button>
                                            </li>
                                        </ul>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="login-pane" role="tabpanel"
                                                aria-labelledby="login" tabIndex="0">
                                                <div className="login_from">
                                                    <form onSubmit={handleLoginSubmit}>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="mobile">Mobile Number</label>
                                                            <input type="text" name="mobile" id="mobile" placeholder="Mobile Number"
                                                                className="form-control" value={mobile} onChange={handleLoginChange} required />
                                                        </div>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="password">Password</label>
                                                            <div className="password_wepper">
                                                                <input type={showPassword ? "text" : "password"} name="password" id="password"
                                                                    className="form-control" placeholder="Password" value={password} onChange={handleLoginChange} required />
                                                                <img
                                                                    src={showPassword ? "images/Get Started eye.png" : "images/light-eye-slash.png"}
                                                                    alt="Toggle Password Visibility"
                                                                    className="eye-icon"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Link to="/forgotpassword" className="forgot-password">
                                                            <span>Forgot Password?</span>
                                                        </Link>
                                                        <div className="">
                                                            <button type="submit" className="login_btn text-white">Login</button>
                                                        </div>
                                                        <p>or</p>
                                                        <div className="scan_btn" onClick={() => setIsScannerOpen(true)}>
                                                            <div className="login_btn">
                                                                <img src="images/Get Started scan.png" alt="" /> <a href="#">Scan
                                                                    Now</a>
                                                            </div>
                                                        </div>
                                                        <div className="qr_siticker">
                                                            <p>Get your QR Sticker. <Link to="https://lifecode.co.in/" target='_blank'> Click here</Link></p>
                                                            <p>Powered by iShivax®</p>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="register-pane" role="tabpanel"
                                                aria-labelledby="register" tabIndex="0">
                                                <div className="login_from">
                                                    <form onSubmit={handleRegistrationSubmit}>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="fullName">Full Name</label>
                                                            <input type="text" name="fullName" id="fullName"
                                                                className="form-control" value={registrationData.fullName}
                                                                onChange={handleRegistrationChange} placeholder="Full Name" required />
                                                        </div>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="mobileNumber">Mobile Number</label>
                                                            <input type="text" name="mobileNumber" id="mobileNumber"
                                                                className="form-control" value={registrationData.mobileNumber}
                                                                onChange={handleRegistrationChange} placeholder="Mobile Number" required />
                                                        </div>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="email">Email address</label>
                                                            <input type="text" name="email" id="email"
                                                                className="form-control" value={registrationData.email}
                                                                onChange={handleRegistrationChange} placeholder="Email" required />
                                                        </div>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="password">Password</label>
                                                            <div className="password_wepper">
                                                                <input type={showPassword ? "text" : "password"} name="password" id="password"
                                                                    className="form-control" value={registrationData.password}
                                                                    onChange={handleRegistrationChange} placeholder="Password" required />
                                                                <img
                                                                    src={showPassword ? "images/Get Started eye.png" : "images/light-eye-slash.png"}
                                                                    alt="Toggle Password Visibility"
                                                                    className="eye-icon"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group from_chek">
                                                            <label htmlFor="confirmPassword">Confirm Password</label>
                                                            <div className="password_wepper">
                                                                <input type={showConfirmPassword ? "text" : "password"} name="confirmPassword" id="confirmPassword"
                                                                    className="form-control" value={registrationData.confirmPassword}
                                                                    onChange={handleRegistrationChange} placeholder="Confirm Password" required />
                                                                <img
                                                                    src={showConfirmPassword ? "images/Get Started eye.png" : "images/light-eye-slash.png"}
                                                                    alt="Toggle Password Visibility"
                                                                    className="eye-icon"
                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                    style={{ cursor: 'pointer' }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <button type="submit" className="login_btn text-white">Next</button>
                                                        </div>
                                                        <div className="qr_siticker">
                                                            <p>Powered by iShivax®</p>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isScannerOpen && (
                    <QRCodeScanner
                        onScan={handleScan}
                        onError={(error) => console.error("Error scanning QR code:", error)}
                        onClose={() => setIsScannerOpen(false)}
                    />
                )}
            </section>
        </>
    );
};

export default Login;
