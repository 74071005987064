import { Link } from "react-router-dom"

const Qrhover = () => {
    return (
        <>

            <Link to="/addqr" className="add-qr-code">
                <div class="add_qr_wepper">
                    <img src="images/scan-barcode-icon.png" alt="" />
                    <span>Add QR</span>
                </div>
            </Link>

        </>
    )
}

export default Qrhover 