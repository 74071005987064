import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useTheme } from "../../containers";
import toast from 'react-hot-toast';
import { apiurl, DAPI_URL } from '../../_config';

const EditProfile = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();

    const [profileData, setProfileData] = useState({
        fname: '',
        dob: '',
        bloodGroup: 'AB+',
        gender: 'male',
        pincode: '',
        emergencyContact: '',
        emergencyContact2: '',
        city: '',
        state: '',
        address: '',
        countryId: '',
        stateId: '',
        cityId: ''
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${apiurl}${DAPI_URL?.GetCountries}`);
                if (response.status === 200 && response.data.status === 1) {
                    setCountries(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        const fetchUserData = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                console.error("No registered ID found in local storage");
                return;
            }

            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.UserData}`, { id: userId });
                if (response.status === 200 && response.data.status === 1) {
                    const data = response.data.data;
                    setProfileData((prevState) => ({
                        ...prevState,
                        fname: data.fname,
                        dob: data.dob,
                        bloodGroup: data.bloodGroup,
                        gender: data.gender,
                        emergencyContact: data.emergencyContact || '',
                        emergencyContact2: data.emergencyContact2 || '',
                        address: data.address || '',
                        // city: data.city || '',
                        // state: data.state || '',
                        pincode: data.pincode || '',
                    }));
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchCountries();
        fetchUserData();
    }, []);

    const handleCountryChange = async (e) => {
        const countryId = e.target.value;
        setProfileData((prevState) => ({
            ...prevState,
            countryId,
            stateId: '',
            cityId: '',
            state: '',
            city: ''
        }));

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.GetStates}`, { country_id: countryId });
            if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
                setStates(response.data.data);
            } else {
                console.error('Expected an array for states, but got:', response.data);
                setStates([]);
            }
        } catch (error) {
            console.error('Error fetching states:', error);
            setStates([]);
        }
    };

    const handleStateChange = async (e) => {
        const stateId = e.target.value;
        setProfileData((prevState) => ({ ...prevState, stateId, cityId: '', city: '' }));

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.GetCities}`, { stateId });
            if (response.status === 200 && response.data && Array.isArray(response.data.data)) {
                setCities(response.data.data);
            } else {
                console.error('Expected an array for cities, but got:', response.data);
                setCities([]);
            }
        } catch (error) {
            console.error('Error fetching cities:', error);
            setCities([]);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleUpdateProfile = async (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('userId');

        const payload = {
            id: Number(userId),
            fname: profileData.fname,
            dob: profileData.dob,
            bloodGroup: profileData.bloodGroup,
            gender: profileData.gender,
            pincode: profileData.pincode ? Number(profileData.pincode) : "",
            emergencyContact: profileData.emergencyContact,
            emergencyContact2: profileData.emergencyContact2,
            city: profileData.city,
            state: profileData.state,
            address: profileData.address,
            countryId: profileData.countryId,
            stateId: profileData.stateId,
            cityId: profileData.cityId
        };

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.UpdateProfile}`, payload);

            if (response.status === 200 && response.data.status === 1) {
                toast.success("Profile updated successfully!");
                navigate("/profile");
            } else {
                toast.error(response.data.message || "Update failed");
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error("Please try again later.");
        }
    };

    return (
        <section className="same_width_page complete_profile_page edit_profile_page">
            <div className="container">
                <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                    <div className="same_color_body">
                        <div className="login_wepper">
                            <div className="same_arrow_screen">
                                <Link to="/profile" className="profile">
                                    <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow" />
                                    <img src="images/back-arrow-light.png" alt="" className="light_arrow" />
                                </Link>
                                <span className="light_arow_text">Edit Profile</span>
                            </div>
                            <div className="edit_profile_wepper">
                                <img src="images/light-user-icon.png" alt="" />
                            </div>
                            <div className="login_inner">
                                <div className="login_from">
                                    <form onSubmit={handleUpdateProfile}>
                                        {/* Form fields go here */}
                                        <div className="form-group from_chek">
                                            <label htmlFor="fname">Full Name</label>
                                            <input
                                                type="text"
                                                name="fname"
                                                id="fname"
                                                className="form-control"
                                                value={profileData.fname}
                                                onChange={handleChange}
                                                placeholder="Enter your full name"
                                                required
                                            />
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="dob">Date of Birth</label>
                                            <input
                                                type="date"
                                                name="dob"
                                                id="dob"
                                                className="form-control"
                                                value={profileData.dob}
                                                onChange={handleChange}
                                                max={new Date().toISOString().split('T')[0]} // Set max date to today
                                            />
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="bloodGroup">Blood Group</label>
                                            <select
                                                name="bloodGroup"
                                                id="bloodGroup"
                                                className="blood_group"
                                                value={profileData.bloodGroup}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Blood Group</option>
                                                <option value="AB+">AB+</option>
                                                <option value="B+">B+</option>
                                                <option value="O+">O+</option>
                                                <option value="A+">A+</option>
                                                <option value="AB-">AB-</option>
                                                <option value="B-">B-</option>
                                                <option value="O-">O-</option>
                                                <option value="A-">A-</option>
                                            </select>
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="gender">Gender</label>
                                            <select
                                                name="gender"
                                                id="gender"
                                                className="blood_group"
                                                value={profileData.gender}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Gender</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                     {/*     <div className="form-group from_chek">
                                            <label htmlFor="country">Country</label>
                                            <select
                                                name="countryId"
                                                id="country"
                                                className="form-control"
                                                value={profileData.countryId}
                                                onChange={handleCountryChange}
                                            >
                                                <option value="">Select Country</option>
                                                {countries.map((country) => (
                                                    <option key={country.id} value={country.id}>
                                                        {country.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="state">State</label>
                                            <select
                                                name="stateId"
                                                id="state"
                                                className="form-control"
                                                value={profileData.stateId}
                                                onChange={handleStateChange}
                                            >
                                                <option value="">Select State</option>
                                                {Array.isArray(states) && states.length > 0 ? (
                                                    states.map((state) => (
                                                        <option key={state.id} value={state.id}>
                                                            {state.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">No states available</option>
                                                )}
                                            </select>
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="city">City</label>
                                            <select
                                                name="cityId"
                                                id="city"
                                                className="form-control"
                                                value={profileData.cityId}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select City</option>
                                                {Array.isArray(cities) && cities.length > 0 ? (
                                                    cities.map((city) => (
                                                        <option key={city.id} value={city.id}>
                                                            {city.name}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">No cities available</option>
                                                )}
                                            </select>
                                        </div> */}
                                        <div className="form-group from_chek">
                                            <label htmlFor="pincode">Pin Code</label>
                                            <input
                                                type="text"
                                                name="pincode"
                                                id="pincode"
                                                className="form-control"
                                                value={profileData.pincode}
                                                onChange={handleChange}
                                                placeholder="Enter your pin code"
                                            />
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="emergencyContact">Primary Emergency Contact No.*</label>
                                            <input
                                                type="text"
                                                name="emergencyContact"
                                                id="emergencyContact"
                                                className="form-control"
                                                value={profileData.emergencyContact}
                                                onChange={handleChange}
                                                placeholder="Enter primary emergency contact"
                                                required
                                            />
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="emergencyContact2">Secondary Emergency Contact No.</label>
                                            <input
                                                type="text"
                                                name="emergencyContact2"
                                                id="emergencyContact2"
                                                className="form-control"
                                                value={profileData.emergencyContact2}
                                                onChange={handleChange}
                                                placeholder="Enter secondary emergency contact"
                                            />
                                        </div>
                                        <div className="form-group from_chek">
                                            <label htmlFor="address">Address</label>
                                            <input
                                                type="text"
                                                name="address"
                                                id="address"
                                                className="form-control"
                                                value={profileData.address}
                                                onChange={handleChange}
                                                placeholder="Enter your address"
                                            />
                                        </div>
                                        <div className="">
                                            <button type="submit" className="login_btn text-white">Update Profile</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EditProfile;
