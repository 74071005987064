// Main.js
import React, { useState, useEffect } from 'react';
import Splash from '../splash';
import Login from '../login';


const Main = () => {
    const [showSplash, setShowSplash] = useState(true);

    useEffect(() => {
        // Set a timeout to hide the splash screen and show the login page
        const timer = setTimeout(() => {
            setShowSplash(false);
        }, 1000); // 2000 ms = 2 seconds

        // Cleanup timeout if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {showSplash ? <Splash /> : <Login />}
        </>
    );
}

export default Main;
