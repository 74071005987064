import { Link } from "react-router-dom"
import { useTheme } from "../../../containers";

const Congratulation = () =>
{
    const { isDarkMode, toggleTheme } = useTheme();

    
    const handleGoToLogin = () => {
        // Clear verificationId and verificationMobile from local storage
        localStorage.setItem('verificationId', null);
        localStorage.setItem('verificationMobile', null);
    };

    return(

        <>
         <section class="same_width_page congratulation_page">
        <div class="container">
            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <div class="same_color_body">
                    <div class="login_wepper">
                        <div class="forgot_content">
                            <h2>Congratulation</h2>
                            <p>Your New Password successful updated </p>
                            <div class="login_btn">
                            <Link to="/" className="home" onClick={handleGoToLogin}>Go to Login</Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </section>

        </>
    )
}
export default Congratulation