import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTheme } from "../../../containers";

const Navbar = (props) => {
    const { isDarkMode} = useTheme();
    const location = useLocation();
    const [currentPage, setCurrentPage] = useState('/');

    useEffect(() => {
        setCurrentPage(location.pathname);
    }, [location.pathname]);

    return (

        <>
        <div  className={`bg_img_wepper ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>

            <img src="images/bg.png"  alt="Background" class="dark_arrow light_bg" />
            <img src="images/bg-light1.png" alt="" className="light_arrow light_bg"/>


            <div className="menu_icon">
                <Link to="/home" className="login-page">
                    <img 
                        src={`images/home-icon${currentPage === '/' ? '' : '-dark'}.png`} 
                        alt="Home Icon" 
                    />
                </Link>
                <Link to="/profile" className="forgot-password">
                    <img 
                        src={`images/smale-icon${currentPage === '/profile' ? '-light' : ''}.png`} 
                        alt="Profile Icon" 
                    />
                </Link>
            </div>
            <div className="menu_icon1">
                <Link to="/setting" className="setting-page">
                    <img 
                        src={`images/toggle-icon${currentPage === '/setting' ? '-light' : ''}.png`} 
                        alt="Settings Icon" 
                    />
                </Link>
                <Link to="/logout" className="logout-page">
                    <img 
                        src={`images/login-icon${currentPage === '/logout' ? '--light' : ''}.png`} 
                        alt="Logout Icon" width="31" height="30"
                    />
                </Link>
            </div>
        </div>

        </>
    );
}

export default Navbar;
