// Home.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../layout/navbar";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import Qrhover from "../layout/qrhover";
import { useTheme } from "../../containers";
import { apiurl, DAPI_URL } from "../../_config";
import axios from "axios";
import QRCodeScanner from "../../containers/QRCodeScanner";
import toast from 'react-hot-toast';
 
const Home = () => {
    const { isDarkMode } = useTheme();
    const [carouselItems, setCarouselItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const navigate = useNavigate();
    const [vehicleNumber, setVehicleNumber] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const userId = localStorage.getItem('userId');
            if (!userId) {
                toast.error("No registered ID found in local storage");
                setIsLoading(false);
                return;
            }

            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.SliderDetail}`, { id: userId });
                if (response.status === 200 && response.data.status === 1) {
                    setCarouselItems(response.data.data);
                }
            } catch (error) {
                toast.error("Error fetching slider data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const responsive = {
        superLargeDesktop: { breakpoint: { max: 3000, min: 1025 }, items: 2 },
        desktop: { breakpoint: { max: 1024, min: 769 }, items: 2 },
        tablet: { breakpoint: { max: 768, min: 464 }, items: 1 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
    };

    const handleScan = async (qucodeId) => {
        try {
            // Extract the code from the scanned URL
            const extractedCode = qucodeId.split('=')[1]; // Get the part after '='
            
            if (!extractedCode) {
                toast.error("Invalid QR code format.");
                return;
            }
    
            const response = await axios.post(`${apiurl}${DAPI_URL?.QrCodeDetails}`, { qucodeId: extractedCode });
            
            if (response.data.status === 1) {
                navigate("/QrDetail", { state: response.data.data });
            } else {
                alert("Invalid QR code.");
            }
        } catch (error) {
            toast.error("Error fetching QR code details:", error);
        } finally {
            setIsScannerOpen(false);
        }
    };

    const handleFind = async (event) => {
        event.preventDefault();

        if (!vehicleNumber) {
            alert("Please enter a vehicle number.");
            return;
        }

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.QrCodeDetails}`, { qucodeId: vehicleNumber });
            
            if (response.data.status === 1) {
                navigate("/QrDetail", { state: response.data.data });
            } else {
                toast.error("Invalid vehicle number.");
            }
        } catch (error) {
            toast.error("Error fetching QR code details:", error);
        }
    };
    

    return (
        <>
            <section className="same_width_page Home_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <img src="images/user-icon.png" alt="" className="dark_arrow" />
                                    <img src="images/light-user-icon.png" alt="" className="light_arrow" />
                                    <img src="images/call-icon.png" alt="" className="dark_arrow" />
                                    <img src="images/light-call-icon.png" alt="" className="light_arrow" />
                                </div>
                                <div className="login_inner">
                                    <div className="banner">
                                        <img src="images/qrcode.png" alt="" />
                                        <div className="find_wepper">
                                            <div className="form-group from_chek">
                                                <input 
                                                    type="text" 
                                                    name="phonenumber" 
                                                    id="phonenumber" 
                                                    className="form-control" 
                                                    placeholder="Enter Vehicle Number" 
                                                    required 
                                                    value={vehicleNumber} 
                                                    onChange={(e) => setVehicleNumber(e.target.value)} 
                                                />
                                                <input 
                                                    type="submit" 
                                                    value="Find" 
                                                    className="find_btn" 
                                                    onClick={handleFind} 
                                                />
                                            </div>
                                        </div>
                                        <div className="home_scan_btn only_light_button">
                                            <div className="scan_btn" onClick={() => setIsScannerOpen(true)}>
                                                <div className="login_btn active">
                                                    <img src="images/scan-white.png" alt="" /> <span>Scan QR</span>
                                                </div>
                                            </div>
                                            <Link to="/parkvehicle" className="park-vehicle">
                                                <div className="scan_btn">
                                                    <div className="login_btn">
                                                        <img src="images/park-vehicle-icon.png" alt="" className="dark_arrow" />
                                                        <img src="images/light-park-vehicle-icon.png" alt="" className="light_arrow" />
                                                        <span>Park Vehicle</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        {isLoading ? (
                                            <div>Loading...</div>
                                        ) : (
                                            <Carousel
                                                responsive={responsive}
                                                showDots={false}
                                                arrows={false}
                                                infinite={true}
                                                autoPlay={true}
                                                autoPlaySpeed={3000}
                                                transitionDuration={500}
                                                containerClass="carousel-container"
                                            >
                                                {carouselItems.map(item => (
                                                    <Link to={item.redirection_url} key={item.id}>
                                                        <div className="item">
                                                            <div className="banner_slider_wepper">
                                                                <img src={`https://lifecode.co.in/app/${item.image_url}`} alt={item.name} className="dark_arrow" />
                                                                <img src={`https://lifecode.co.in/app/${item.image_url}`} alt={item.name} className="light_arrow" />
                                                            </div>
                                                        </div>
                                                    </Link>
                                                ))}
                                            </Carousel>
                                        )}
                                        <Qrhover />
                                    </div>
                                    <Navbar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isScannerOpen && (
                    <QRCodeScanner
                        onScan={handleScan}
                        onError={(error) => console.error("Error scanning QR code:", error)}
                        onClose={() => setIsScannerOpen(false)}
                    />
                )}
            </section>
        </>
    );
};

export default Home;
