import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../layout/navbar';
import Qrhover from '../layout/qrhover';
import { useTheme } from '../../containers';
import axios from 'axios';
import { apiurl, DAPI_URL } from '../../_config';

const Setting = () => {
    const { isDarkMode, toggleTheme } = useTheme();
    const userId = localStorage.getItem('userId');

    // Local state to manage the toggle states
    const [settings, setSettings] = useState({
        callMsgStatus: localStorage.getItem('callMsgStatus') === '0',
        locationShare: localStorage.getItem('locationShare') === '0',
        msgStatus: localStorage.getItem('msgStatus') === '0',
        womenSafety: localStorage.getItem('womenSafety') === '0',
    });

    // Function to update settings in local storage
    const updateLocalStorage = (key, value) => {
        localStorage.setItem(key, value);
        setSettings(prev => ({
            ...prev,
            [key]: value === '1' ? false : true // Toggle the state for the UI
        }));
    };

    // Function to update women safety status
    const updateWomenSafety = async (value) => {
        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.UpdatewomansSafety}`, {
                id: userId,
                women_safety: value
            });
            

            if (response.data.status === 1) {
                updateLocalStorage('womenSafety', response.data.data.women_safety);
            }
        } catch (error) {
            console.error('Error updating women safety status:', error);
        }
    };

    // Function to update call message status
    const updateCallMsgStatus = async (value) => {
        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.UpdateCallMsgStatus}`, {
                id: userId,
                callMsgStatus: value
            });

            if (response.data.status === 1) {
                updateLocalStorage('callMsgStatus', response.data.data.callMsgStatus);
            }
        } catch (error) {
            console.error('Error updating call message status:', error);
        }
    };

    // Function to update message status
    const updateMsgStatus = async (value) => {
        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.UpdateMsgStatus}`,{
                id: userId,
                msgStatus: value
            });

            if (response.data.status === 1) {
                updateLocalStorage('msgStatus', response.data.data.msgStatus);
            }
        } catch (error) {
            console.error('Error updating message status:', error);
        }
    };

    // Function to update location share status
    const updateLocationShare = async (value) => {
        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.UpdatLocation}`,{
                id: userId,
                locationshare: value
            });

            if (response.data.status === 1) {
                updateLocalStorage('locationShare', response.data.data.locationshare);
            }
        } catch (error) {
            console.error('Error updating location share status:', error);
        }
    };

    // Handlers for toggle switches
    const handleWomenSafetyToggle = () => {
        const newValue = settings.womenSafety ? '1' : '0';
        updateWomenSafety(newValue);
    };

    const handleCallMsgToggle = () => {
        const newValue = settings.callMsgStatus ? '1' : '0';
        updateCallMsgStatus(newValue);
    };

    const handleMsgToggle = () => {
        const newValue = settings.msgStatus ? '1' : '0';
        updateMsgStatus(newValue);
    };

    const handleLocationShareToggle = () => {
        const newValue = settings.locationShare ? '1' : '0';
        updateLocationShare(newValue);
    };

    return (
        <>
            <section className="same_width_page complete_profile_page setting_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/contactus" className="profile">
                                        <img src="images/massage-icon.png" alt="" className="dark_arrow" />
                                        <img src="images/lightmassage-icon.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">Settings</span>
                                </div>
                                <div className="login_inner">
                                    <Link to="/myqr" className="My-qr-List">
                                        <div className="setting_banner">
                                            <img src="images/setting_banner.png" alt="" className="dark_arrow" />
                                            <img src="images/light-setting-banner.png" alt="" className="light_arrow" />
                                            <div className="banner_content">
                                                <p>MY</p>
                                                <h3>QR List</h3>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="dark_wepper_siwtch">
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckWomenSafety"
                                                checked={!settings.womenSafety}
                                                onChange={handleWomenSafetyToggle}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckWomenSafety">
                                                Hide women safety button
                                            </label>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckPhoneNumber"
                                                checked={!settings.callMsgStatus}
                                                onChange={handleCallMsgToggle}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckPhoneNumber">
                                                Hide my phone number
                                            </label>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckMsgOwner"
                                                checked={!settings.msgStatus}
                                                onChange={handleMsgToggle}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckMsgOwner">
                                                Disable message owner
                                            </label>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="flexSwitchCheckLocationShare"
                                                checked={!settings.locationShare}
                                                onChange={handleLocationShareToggle}
                                            />
                                            <label className="form-check-label" htmlFor="flexSwitchCheckLocationShare">
                                                Disable Share location
                                            </label>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="Theme"
                                                checked={isDarkMode}
                                                onChange={toggleTheme}
                                            />
                                            <label className="form-check-label" htmlFor="Theme">
                                                Theme
                                            </label>
                                        </div>
                                        <div className="delete_btn">
                                            <Link to="/login" className="test-name">
                                                <img src="images/delete-icon.png" alt="" />
                                                Delete My Account
                                            </Link>
                                        </div>
                                        <div className="banner">
                                            <Qrhover />
                                        </div>
                                    </div>
                                </div>
                                <Navbar />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Setting;
