import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { apiurl, DAPI_URL } from "../../../_config";
import toast from "react-hot-toast";
import { useTheme } from "../../../containers";

const ForgotPassword = () => {
    const { isDarkMode } = useTheme(); // Removed toggleTheme if not used
    const [mobile, setMobile] = useState(""); // State for mobile input
    const navigate = useNavigate(); // Initialize useNavigate

    const sendForgetOTP = async (mobile) => {
        try {
            console.log('Sending OTP for mobile:', mobile); // Debug log
            const response = await axios.post(`${apiurl}${DAPI_URL?.SendForgetOTP}`, {
                mobile: mobile // Send mobile number in the request body
            });

            console.log('Response:', response); // Debug log

            if (response.status === 200) {
                if (response.data.status === 1 && response.data.message === "User not registered with us!") {
                    toast.error(response.data.message);  
                } else {
                    localStorage.setItem('verificationId', response.data.data.id);
                    localStorage.setItem('verificationMobile', response.data.data.mobile);
                    
                    toast.success("OTP sent successfully");
                    navigate("/verifycode");  
                }
            } else {
                toast.error("Something went wrong"); // Show error toast
            }
        } catch (error) {
            console.error('Error:', error); // Debug log
            toast.error("Please try again later."); // Show error toast
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        if (validateMobile(mobile)) {
            sendForgetOTP(mobile); // Call sendForgetOTP with mobile number
        } else {
            toast.error("Invalid mobile number format"); // Show error toast for invalid mobile number format
        }
    };

    // Simple validation for mobile number
    const validateMobile = (mobile) => {
        const mobileRegex = /^[+]?[0-9]{10,15}$/; // Modify regex as needed
        return mobileRegex.test(mobile);
    };

    return (
        <>
            <section className="same_width_page forgot_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/login" className="forgot-password">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow" />
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">Forgot Password</span>
                                </div>
                                <div className="login_inner">
                                    <div className="logo">
                                        <img src="images/Forgot Password lock.png" alt="" width="120" className="dark_forgot_img" />
                                        <img src="images/light-forgot.png" alt="" width="120" className="light_forgot_img" />
                                    </div>
                                    <div className="forgot_content">
                                        <h2>Forgot Password</h2>
                                        <p>Enter your mobile/email for the verification process we will send 4 digit code to your mobile/email.</p>
                                    </div>
                                    <form onSubmit={handleSubmit} className="login_tab_main">
                                        <div className="form-group from_chek">
                                            <label htmlFor="phonenumber">Mobile Number / Email address</label>
                                            <input
                                                type="text"
                                                name="phonenumber"
                                                id="phonenumber"
                                                className="form-control"
                                                value={mobile}
                                                onChange={(e) => setMobile(e.target.value)} // Update state on input change
                                                required
                                                placeholder="Mobile Number"
                                            />
                                        </div>
                                        <div className="">
                                            <button type="submit" className="login_btn text-white">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgotPassword;
