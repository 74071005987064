import { Link, useNavigate } from "react-router-dom"
import Navbar from "../layout/navbar"
import { useTheme } from "../../containers";
import { useAuth } from "../../containers/AuthContext";

const Logout = () => {
    const { isDarkMode, toggleTheme } = useTheme();
    const { logout } = useAuth();
    const navigate = useNavigate();


    const handleLogout = () => {
        logout(); // Call the logout function
        navigate("/login"); // Redirect to login page
    };

    return (
        <>
            <section class="same_width_page logout_page">
                <div class="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div class="same_color_body">
                            <div class="logout_wepper">
                                <div class="logout_inner">
                                    <img src="images/lougot-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/pagelight-logout.png" alt="" class="light_arrow"/>
                                            <div class="logout_heding">
                                                <h2>Logout?</h2>
                                                <p>Are you sure you want to Logout “Lifecode”</p>
                                                <div class="logout_btn">
                                                    {/* <!-- <a href="#">Yes</a> --> */}
                                                   <Link onClick={handleLogout} className="active">Logout</Link>
                                                </div>
                                            </div>
                                        </div>
                                       <Navbar/>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </section>


                </>
                )
}

                export default Logout