import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTheme } from "../../../containers";
import { apiurl, DAPI_URL } from '../../../_config';
import toast from 'react-hot-toast';

const CompleteProfile = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        dob: '',
        bloodGroup: 'AB+',
        gender: 'Male',
        pincode: '',
        emergencyContact: '',
        emergencyContact2: '',
        city: '',
        state: '',
        address: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const id = localStorage.getItem('registeredId');

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.CompleteProfile}`, {
                id,
                dob: formData.dob,
                bloodGroup: formData.bloodGroup,
                gender: formData.gender,
                pincode: formData.pincode,
                emergencyContact: formData.emergencyContact,
                emergencyContact2: formData.emergencyContact2,
                city: formData.city,
                state: formData.state,
                address: formData.address,
            });

            if (response.status === 200) {
                toast.success("Profile completed successfully");
                navigate("/newcongrat");
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error("Please try again later.");
        }
    };

    return (
        <>
            <section className="same_width_page complete_profile_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/" className="forgot-password">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow" />
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">Complete Profile</span>
                                </div>
                                <div className="login_inner">
                                    <div className="login_from">
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group from_chek">
                                                <label htmlFor="dob">Date of Birth</label>
                                                <input
                                                    type="date"
                                                    name="dob"
                                                    id="dob"
                                                    className="form-control"
                                                    value={formData.dob}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="bloodGroup">Blood Group*</label>
                                                <div className="wrap">
                                                    <select
                                                        name="bloodGroup"
                                                        id="bloodGroup"
                                                        className="blood_group"
                                                        value={formData.bloodGroup}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="AB+">AB+</option>
                                                        <option value="A+">A+</option>
                                                        <option value="B+">B+</option>
                                                        <option value="O+">O+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B-">B-</option>
                                                        <option value="O-">O-</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="gender">Gender</label>
                                                <div className="wrap">
                                                    <select
                                                        name="gender"
                                                        id="gender"
                                                        className="blood_group"
                                                        value={formData.gender}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="pincode">Pin Code*</label>
                                                <input
                                                    type="text"
                                                    name="pincode"
                                                    id="pincode"
                                                    className="form-control"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div> 
                                            <div className="form-group from_chek">
                                                <label htmlFor="emergencyContact">Primary Emergency Contact No.*</label>
                                                <input
                                                    type="text"
                                                    name="emergencyContact"
                                                    id="emergencyContact"
                                                    className="form-control"
                                                    value={formData.emergencyContact}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="emergencyContact2">Secondary Emergency Contact No.</label>
                                                <input
                                                    type="text"
                                                    name="emergencyContact2"
                                                    id="emergencyContact2"
                                                    className="form-control"
                                                    value={formData.emergencyContact2}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="city">City*</label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    className="form-control"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="state">State*</label>
                                                <input
                                                    type="text"
                                                    name="state"
                                                    id="state"
                                                    className="form-control"
                                                    value={formData.state}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group from_chek">
                                                <label htmlFor="address">Address*</label>
                                                <input
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    className="form-control"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="">
                                                <button type="submit" className="login_btn text-white">Next</button>
                                            </div>
                                            <div className="qr_siticker">
                                                <p>Powered by iShivax®</p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CompleteProfile;
