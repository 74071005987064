import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../layout/navbar";
import Qrhover from "../layout/qrhover";
import { useTheme } from "../../containers";
import axios from "axios";
import { apiurl, DAPI_URL } from "../../_config";

const Profile = () => {
    const { isDarkMode } = useTheme();
    const [userData, setUserData] = useState({
        fname: "",
        lname: "",
        dob: "",
        gender: "",
        mobile: "",
        email: "",
        address: "",
        bloodGroup: "",
        emergencyContact: "",
        emergencyContact2: "",
    });

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = localStorage.getItem('userId'); // Get registeredId from local storage
            if (!userId) {
                console.error("No registered ID found in local storage");
                return;
            }

            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.UserData}`,{id:userId}); 
              
                if (response.status === 200 && response.data.status === 1) {
                    const data = response.data.data;
                    setUserData({
                        fname: data.fname,
                        lname: data.lname,
                        dob: data.dob,
                        gender: data.gender,
                        mobile: data.mobile,
                        email: data.email,
                        address: data.address,
                        bloodGroup: data.bloodGroup,
                        emergencyContact: data.emergencyContact,
                        emergencyContact2: data.emergencyContact2,
                    });
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, []);

    return (
        <>
            <section className="same_width_page profile_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/editprofile" className="edit-profile">
                                        <img src="images/profile-edit.png" alt="" className="dark_arrow" />
                                        <img src="images/light-profile-icon.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">Profile</span>
                                </div>
                                <div className="login_inner">
                                    <div className="banner">
                                        <img src="images/light-user-icon.png" alt="" />
                                        <div className="profile_bg_wepper">
                                            <div className="profile_inner">
                                                <div className="profile_name">
                                                    <h2>{userData.fname} {userData.lname}</h2>
                                                    <p>{userData.gender}</p>
                                                </div>
                                                <div className="profile_datalis_wepper">
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/user-call.png" alt="" className="dark_arrow" />
                                                        <img src="images/lightprofile-call.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Mobile Number</span>
                                                            <h4>{userData.mobile}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/user-call.png" alt="" className="dark_arrow" />
                                                        <img src="images/lightprofile-call.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Primary Emergency Mobile No.</span>
                                                            <h4>{userData.emergencyContact2 || 'N/A'}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/useremail.png" alt="" className="dark_arrow" />
                                                        <img src="images/lightprofile-email.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Email Address</span>
                                                            <h4>{userData.email}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/date-of-birth.png" alt="" className="dark_arrow" />
                                                        <img src="images/lightprofile-dete.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Date of Birth</span>
                                                            <h4>{userData.dob}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/blood-group.png" alt="" className="dark_arrow" />
                                                        <img src="images/lightprofile-blood.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Blood Group</span>
                                                            <h4>{userData.bloodGroup}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="profile_inner_datalis_wepper">
                                                        <img src="images/location.png" alt="" className="dark_arrow" />
                                                        <img src="images/light-lightprofile-map.png" alt="" className="light_arrow" />
                                                        <div className="user_datalis_inner">
                                                            <span>Address</span>
                                                            <h4>{userData.address}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Qrhover />
                                        </div>
                                    </div>
                                    <Navbar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Profile;
