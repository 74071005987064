import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../containers";
import { apiurl, DAPI_URL } from "../../_config";
import axios from "axios";

const Myqr = () => {
    const { isDarkMode } = useTheme();
    const [qrCodes, setQrCodes] = useState([]);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedQrCode, setSelectedQrCode] = useState(null);
    
    useEffect(() => {
        const fetchQRCodes = async () => {
            const userId = localStorage.getItem("userId");
            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.GetAllQrCodes}`, { id: userId });
                
                if (response.data.status === 1) {
                    setQrCodes(response.data.data);
                }
            } catch (error) {
                console.error("Error fetching QR codes:", error);
            }
        };
        
        fetchQRCodes();
    }, []);

    const handleExpireClick = (qrCode) => {
        console.log("Expire clicked for:", qrCode); // Debugging line
        setSelectedQrCode(qrCode);
        setIsPopupOpen(true);
    };

    const handleExpireConfirmation = async () => {
        if (!selectedQrCode) return;

        const { qucodeId, type } = selectedQrCode;
        const userId = localStorage.getItem("userId");

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.ExpireQrCode}`, {
                id: userId,
                qucodeId,
                type
            });

            if (response.data.status === 1) {
                setQrCodes((prev) => prev.filter((code) => code.qucodeId !== qucodeId));
                setIsPopupOpen(false);
                setSelectedQrCode(null);
            } else {
                console.error("Failed to expire QR code:", response.data.message);
            }
        } catch (error) {
            console.error("Error expiring QR code:", error);
        }
    };

    return (
        <>
            <section className="same_width_page addqr_page addqr_pageList">
                <div className="container">
                    <div id="body" className={`${isDarkMode ? "dark-mode" : "light-mode"} ${qrCodes.length > 1 ? "multiple-items" : ""}`}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/setting" className="setting-page">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow" />
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">My QR List</span>
                                </div>
                                {qrCodes.length > 0 ? (
                                    qrCodes.map((qrCode) => (
                                        <div className="qr_bg" key={qrCode.qucodeId}>
                                            <img src="images/QR-bg.png" alt="" className="w-100 dark_arrow" />
                                            <img src="images/light-QR-bg.png" alt="" className="w-100 light_arrow" />
                                            <div className="qr_content_wepper">
                                                <div className="qr_inner">
                                                    <span>
                                                        <img src="images/tag-dote.png" alt="" />
                                                        {qrCode.type === "4vilar" ? "Four Wheeler" : "Two Wheeler"}
                                                    </span>
                                                    <p 
                                                        style={{ cursor: qrCode.status === "1" ? 'pointer' : 'default' }} 
                                                        onClick={() => qrCode.status === "1" && handleExpireClick(qrCode)}
                                                    >
                                                        {qrCode.status === "1" ? "Active" : "Expired"}
                                                    </p>
                                                </div>
                                                <hr />
                                            </div>
                                            <div className="qrlist_wepper">
                                                <div className="qrinner_data">
                                                    <img src="images/qr-image.png" alt="" className="dark_arrow" />
                                                    <img src="images/light-QR.png" alt="" className="light_arrow" />
                                                    <div className="qr_text">
                                                        <div className="qr_inner_wepper">
                                                            <div className="qr_vehicle">
                                                                <span>Vehicle No.</span>
                                                                <span><strong>{qrCode.vehicleNumber}</strong></span>
                                                            </div>
                                                            <div className="qr_vehicle">
                                                                <span>OR Code ID</span>
                                                                <span><strong>{qrCode.qucodeId}</strong></span>
                                                            </div>
                                                        </div>
                                                        <div className="qr_vehicle mt-2 mobile_flex">
                                                            <span>Use From</span>
                                                            <span><strong>{qrCode.useDate}</strong></span>
                                                        </div>
                                                    </div>
                                                    {qrCode.expireDate && (
                                                        <div className="expired_data">
                                                            <span>
                                                                Expired On:
                                                                <img src="images/clock-icon.png" alt="" />
                                                                <span className="date"> {qrCode.expireDate}</span>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No QR codes found.</p>
                                )}
                            </div>
                        </div>

                        {isPopupOpen && (
                            <div className="my_custom">
                                <div id="popUp" className="overlay">
                                    <div className="same_width_page popup_page">
                                        <div className="container">
                                            <div id="body" className="dark-mode">
                                                <div>
                                                    <div className="park_popup_wepper">
                                                        <div className="park_popup_inner">
                                                            <div className="closeModalButton" onClick={() => setIsPopupOpen(false)}>
                                                                <i className="icon fa-solid fa-xmark"></i>
                                                            </div>
                                                            <div className="popup_content">
                                                                <h2 style={{ margin: 0 }}>Confirm Expiration</h2>
                                                                <p>This QR code will permanently expire. Are you sure you want to proceed?</p>
                                                                <div className="login_btn">
                                                                    <button onClick={handleExpireConfirmation}>Yes, Expire</button>
                                                                    <button onClick={() => setIsPopupOpen(false)}>Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Myqr;
