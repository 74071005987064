import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useTheme } from "../../containers";
import { apiurl, DAPI_URL } from '../../_config';
import axios from 'axios';
import QRCodeScanner from '../../containers/QRCodeScanner';
import toast from 'react-hot-toast';

const Addqr = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();

    const [selectedVehicle, setSelectedVehicle] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [qucodeId, setQrcodeId] = useState('');
    const [isScannerOpen, setIsScannerOpen] = useState(false);

    const handleBack = () => {
        navigate(-1);
    };

    const handleVehicleSelect = (vehicle) => {
        setSelectedVehicle(vehicle);
    };

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const images = {
        FourWheeler: {
            light: "images/light-Four-Wheeler.png",
            dark: "images/box1.png",
            selectedLight: "images/Select_Four Wheeler box1.png",
        },
        HeavyVehicle: {
            light: "images/light-Heavy-Vehicle.png",
            dark: "images/Select_Four Wheeler box3.png",
            selectedLight: "images/Select_Heavy Vehicle color.png",
        },
        TwoWheeler: {
            light: "images/light-two-Wheeler.png",
            dark: "images/Select_Four Wheeler box2.png",
            selectedLight: "images/Select_Two Wheeler color.png",
        },
        Tag: {
            light: "images/light-tag.png",
            dark: "images/Select_Four Wheeler box4.png",
            selectedLight: "images/Select_Tag color.png",
        },
    };

    const handleScan = (data) => {
        if (data) {
            setQrcodeId(data);
            handleSubmit(data); // Call handleSubmit with scanned data
        }
    };

    const handleError = (err) => {
        toast.error(err);
    };

    const handleSubmit = async (scannedData) => {
        const userId = localStorage.getItem('userId');
        const vehicleTypeMap = {
            FourWheeler: '4vilar',
            TwoWheeler: '2vilar',
            HeavyVehicle: 'Havy vehicle',
            Tag: 'tag'
        };
        const vehicleType = vehicleTypeMap[selectedVehicle];

        const extractedCode = scannedData.split('=')[1]; // Get the part after '='

        if (!extractedCode) {
            toast.error("Invalid QR code format.");
            // alert("");
            return;
        }

        const requestBody = {
            id: userId,
            qucodeId: extractedCode,
            type: vehicleType,
            petName: "",
            vehicleNumber: vehicleNumber
        };

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.AddQrCode}`, requestBody);
            if (response.data.status === 1) {
                toast.success('QR Code added successfully');
                closePopup();
                setIsScannerOpen(false); // Close the scanner after successful submission
            } else {
                toast.error('QR Code Already Register');
            }
        } catch (error) {
            toast.error('Error:', error);
        }
    };

    const openScanner = () => {
        if (!selectedVehicle || !vehicleNumber) {
            toast.error("Please select a vehicle and enter a vehicle number before scanning.");
        } else {
            setIsScannerOpen(true);
        }
    };

    return (
        <>
            <section className="same_width_page addqr_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link onClick={handleBack} className="home-page">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow" />
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow" />
                                    </Link>
                                    <span className="light_arow_text">Add New QR</span>
                                </div>
                                <div className="login_inner">
                                    <div className="addqr_inner">
                                        <div className="addqr_inner_row">
                                            {Object.entries(images).slice(0, 2).map(([key, value]) => (
                                                <div key={key} className="addqr_inner_wrap" onClick={() => handleVehicleSelect(key)}>
                                                    <img
                                                        src={selectedVehicle === key ? value.selectedLight : (isDarkMode ? value.dark : value.light)}
                                                        alt={key}
                                                    />
                                                    <p className={selectedVehicle === key ? "active" : ""}>{key.replace(/([A-Z])/g, ' $1')}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="addqr_inner_row">
                                            {Object.entries(images).slice(2).map(([key, value]) => (
                                                <div key={key} className="addqr_inner_wrap" onClick={() => handleVehicleSelect(key)}>
                                                    <img
                                                        src={selectedVehicle === key ? value.selectedLight : (isDarkMode ? value.dark : value.light)}
                                                        alt={key}
                                                    />
                                                    <p className={selectedVehicle === key ? "active" : ""}>{key.replace(/([A-Z])/g, ' $1')}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="dark_btn popup_btn_wepper">
                                        <button className="active btn" onClick={() => setIsPopupOpen(true)}>Next</button>
                                    </div>
                                </div>
                                <div className="india_logo">
                                    <p>Powered by iShivax®</p>
                                </div>
                            </div>
                        </div>

                        {isPopupOpen && (
                            <div className="my_custom">
                                <div id="popUp" className="overlay open">
                                    <div className="same_width_page popup_page">
                                        <div className="container">
                                            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                                                <div>
                                                    <div className="park_popup_wepper">
                                                        <div className="park_popup_inner">
                                                            <div className="closeModalButton" onClick={closePopup}>
                                                                <i className="icon fa-solid fa-xmark"></i>
                                                            </div>
                                                            <div className="popup_content">
                                                                <h2>Park My Vehicle</h2>
                                                                <div className="login_from">
                                                                    <form onSubmit={(e) => { e.preventDefault(); openScanner(); }}>
                                                                        <div className="form-group from_chek">
                                                                            <label htmlFor="number">Enter Vehicle No.</label>
                                                                            <input
                                                                                type="text"
                                                                                id="number"
                                                                                className="form-control"
                                                                                value={vehicleNumber}
                                                                                onChange={(e) => {
                                                                                    const upperCaseValue = e.target.value.toUpperCase(); // Convert input to uppercase
                                                                                    if (upperCaseValue.length <= 10) { // Limit input to 10 characters
                                                                                        setVehicleNumber(upperCaseValue);
                                                                                    }
                                                                                }}
                                                                                required
                                                                            />
                                                                        </div>

                                                                        {/* <div className="form-group">
                                                                            {qucodeId && <p>Scanned QR Code ID: {qucodeId}</p>}
                                                                        </div> */}
                                                                    </form>
                                                                    <div>
                                                                        <button className="login_btn" onClick={openScanner}>Open QR Scanner</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {isScannerOpen && (
                            <QRCodeScanner
                                onScan={handleScan}
                                onError={(error) => console.error("Error scanning QR code:", error)}
                                onClose={() => setIsScannerOpen(false)}
                            />
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Addqr;
