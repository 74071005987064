export const apiurl = "https://lifecode.co.in/app/lifecodeapinew1/";
export const DAPI_URL = {
    // saveProductCategory: 'ProductCategory/SaveProductCategory',
    CheckUser:"checkUser",
    Registerwithmobilenew:"registerwithmobilenew",
    Registerwithemail:"registerwithemail",
    VerifyOTP:"verifyotp",
    ResendOTP:"resend_otp",
    ResetPassword:"resetpassword",
    AddPassword:"addpassword",
    UserDetail:"getuserdata",
    AddUserDetail:"adduserdetail",
    VerifyPassword:"verifypassword",
    GetUserDetail:"getuserdata",
    QRDetails:"qrcodedetails",
    SlideDetail:"slider_details",
    GetAllQrCodes:"getallqrcodes",
    AddParkingDetail:"addparkingdetails",
    ParkingDetails:"get_parking_details",
    DeleteParking:"delete_parking_details",
    UpdateCallMessageSttaus:"updatecallmsgstatus",
    UpdateWomanSafty:"update_women_safety_status",
    UpdateMessageStatus:"updatemsgstatus",
    UpdateLocationStatus:"updatelocationstatus",
    ExpireQRCode:"expireqrcode",
    DeleteAccount:"delete_account",
    CheckVehicle:"checkVehicleNumber",
    AddQR:"addqrcode",

    // 1 forgot password otp  
    SendForgetOTP:"send_forget_password_otp",
    VerifyForgetOTP:"verify_forget_password_otp",
    ChangePassword: "change_password",
    RegisterWithMobile: "registerwithmobileapp",
    VerifyOtp: "verifyotp",
    CompleteProfile: "complete_profile",
    UpdateProfile: "update_profile",
    Login: "login",
    UserData: "getuserdata",
    SliderDetail:"slider_details",
    GetCountries:"getcountries",
    GetStates:"get_states",
    GetCities:"getcities",
    AddQrCode : "addqrcode",
    GetAllQrCodes :"getallqrcodes",
    ExpireQrCode :"expireqrcode",
    UpdatewomansSafety: "update_women_safety_status",
    UpdateCallMsgStatus: "updatecallmsgstatus",
    UpdateMsgStatus :"updatemsgstatus",
    UpdatLocation: "updatelocationstatus",
    GetParkingDetail:"get_parking_details",
    DeleteParkingDetail: "delete    _parking_details",
    AddParkingDetials: "addparkingdetails",
    QrCodeDetails:"qrcodedetails",
    



    UpdatePassword:"update_password",
    SendSMS:"sendmassage",
    ChangePasswordOTP:"send_forget_password_otp",
    // ChangePassword:"update_password"
}