import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const QRCodeScanner = ({ onScan = () => {}, onError = () => {}, onClose = () => {} }) => {
    const [data, setData] = useState("Not Found");
    const [hasAccess, setHasAccess] = useState(false);

    const requestCameraAccess = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: { facingMode: { exact: "environment" } } });
            setHasAccess(true);
        } catch (err) {
            console.error("Camera access denied:", err);
            onError(err);
            setHasAccess(false);
            // Attempt to request user-facing camera as a fallback
            try {
                await navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } });
                setHasAccess(true);
            } catch (fallbackErr) {
                console.error("Fallback camera access denied:", fallbackErr);
                onError(fallbackErr);
                setHasAccess(false);
            }
        }
    };

    useEffect(() => {
        requestCameraAccess();

        // Cleanup function to stop the camera when the component unmounts
        return () => {
            setHasAccess(false);
        };
    }, [onError]);

    return (
        <div className="qr-scanner-overlay">
            <button onClick={onClose} className="close-button">Close</button>
            {hasAccess ? (
                <BarcodeScannerComponent
                    onUpdate={(err, result) => {
                        if (result) {
                            setData(result.text);  
                            onScan(result.text);  
                        } else {
                            setData("Not Found");
                        }
                    }}
                    width={500}
                    height={500}
                    constraints={{ facingMode: "environment" }} // Use environment camera
                />
            ) : (
                <p>Please allow camera access to scan QR codes.</p>
            )}
            <p>{data}</p> {/* Display the scanned result */}
        </div>
    );
};

export default QRCodeScanner;
