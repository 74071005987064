import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "../../containers";

const Contactus = () => {
    const { isDarkMode } = useTheme();
    const navigate = useNavigate();


    const handleBack = () => {
        navigate(-1);
    };
    return (
        <>
           <section class="same_width_page addqr_page contact_page">
        <div class="container">
            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <div class="same_color_body">
                    <div class="login_wepper">
                        <div class="same_arrow_screen">
                        <Link  onClick={handleBack} >
                            <img src="images/Forgot Password Menu icon.png" alt="" class="dark_arrow"/>
                            <img src="images/back-arrow-light.png" alt="" class="light_arrow"/>

                            </Link>
                            <span class="light_arow_text">Contact Support</span>
                        </div>
                        <div class="contact_logo_wepper">
                            <img src="images/logo.svg" alt="" class="dark_logo"/>
                            <img src="images/light-logo.svg" alt="" class="light_logo"/>
                            <p><span>“</span>Hope you never meet an accident or
                                loose your pet, But if does...
                                Lifecode will help and assist. <span>”</span></p>

                            <div class="login_btn">
                                <Link to="tel:9829197777" target="_blank"><img src="images/contact-support-call-icon.png" alt=""/> Call Now</Link>
                            </div>
                            <div class="login_btn email_dark_btn">
                                <a href="">

                                    <img src="images/contact-support-sms-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/contact-support-sms-icon.png" alt="" class="light_arrow"/>
                                    Email Us</a>
                            </div>
                        </div>
                        <div class="social_media_wepper">
                            <div class="social_media_inner">
                                <Link to="https://wa.me/9829197777" target="_blank"> 
                                    <img src="images/whatsapp-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/lightwhatsapp-icon.png" alt="" class="light_arrow"/>
                                    
                                </Link>
                                {/* <Link href="#">
                                    <img src="images/facebook-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/lightfacebook-icon.png" alt="" class="light_arrow"/>
                                </Link> */}
                                <Link to="https://www.instagram.com/lifecode.co.in?igsh=ZTA0ODUzaGY3MGd5" target="_blank">
                                    <img src="images/instagram-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/lightinstagram-icon.png" alt="" class="light_arrow"/>
                                </Link>
                                <Link to="http://lifecode.co.in/faq" target="_blank">
                                    <img src="images/massage-icon.png" alt="" class="dark_arrow"/>
                                    <img src="images/lightmassage-icon.png" alt="" class="light_arrow"/>
                                </Link>
                            </div>
                        </div>
                        <div class="terms_content">
                            <p>By using this app, you agree to <Link to="https://lifecode.co.in/terms-and-conditions" target="_blank" ><span>Terms and Conditions</span></Link></p> 
                            <p>We care about your <Link to="https://lifecode.co.in/privacy-policy" target="_blank"> <span>Privacy</span> </Link></p>
                        </div>

                        <div class="india_logo">
                            <img src="images/india-flag.png" alt="" class="light_logo" width="92" height="87"/>
                            <img src="images/light-india-flag.png" alt="" class="dark_logo"/>
                            <p>Powered by iShivax®</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

        </>
    )
}

export default Contactus  