import { Link, useNavigate } from "react-router-dom"
import { useTheme } from "../../../containers";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { apiurl, DAPI_URL } from "../../../_config";

const ConfermMobile = () => {
    const { isDarkMode } = useTheme();
    const [otp, setOtp] = useState(['', '', '', '']); // State to hold each digit of OTP
    const [id, setId] = useState(''); // State to hold user ID
    const [error, setError] = useState(''); // State to hold error messages
    const [success, setSuccess] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        // Retrieve id from local storage
        const storedId = localStorage.getItem('registeredId');
        if (storedId) {
            setId(storedId);
        } else {
            toast.error("No verification data found. Please start the process again.");
            navigate("/completeprofile"); // Redirect to forgot password if no data found
        }
    }, [navigate]);

    const handleInputChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        
        // Move focus to next input if current input is filled
        if (value && index < otp.length - 1) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const otpString = otp.join(''); // Combine OTP digits into a single string

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.VerifyOtp}`, {
                id: id,
                otp: otpString
            });

            if (response.status === 200) {
                if (response.data.status === 1) {
                    toast.success("OTP verified successfully");
                    navigate("/completeprofile"); // Navigate to the new password page on success
                } else {
                    toast.error(response.data.message || "Verification failed");
                }
            } else {
                toast.error("Something went wrong"); // Show error toast
            }
        } catch (error) {
            console.error('Error:', error); // Debug log
            toast.error("Please try again later."); // Show error toast
        }
    };

    return (
        <>
            <section className="same_width_page verify_page">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="login_wepper">
                                <div className="same_arrow_screen">
                                    <Link to="/login/#register-pane"  data-bs-target="#register-pane" className="forgot-password">
                                        <img src="images/Forgot Password Menu icon.png" alt="" className="dark_arrow"/>     
                                        <img src="images/back-arrow-light.png" alt="" className="light_arrow"/>
                                    </Link>
                                    <span className="light_arow_text">Verify Code</span>
                                </div>
                                <div className="login_inner">
                                    <div className="logo">
                                        <img src={`images/${isDarkMode ? 'dark-verify-code.png' : 'light-verify-code.png'}`} alt="" width="120" className={isDarkMode ? 'dark_forgot_img' : 'light_forgot_img'}/>
                                    </div>
                                    <div className="forgot_content">
                                        <h2>Verify Code</h2>
                                        <p>Enter the 4-digit code that you received on your mobile number.</p>
                                        {error && <p className="error-message">{error}</p>}
                                        {success && <p className="success-message">{success}</p>}
                                        <form onSubmit={handleSubmit}>
                                            <div className="verification_code">
                                                {otp.map((digit, index) => (
                                                    <div key={index} className="verification_inner">
                                                        <span>
                                                            <input 
                                                                type="text" 
                                                                id={`otp-input-${index}`} 
                                                                value={digit} 
                                                                onChange={(e) => handleInputChange(index, e.target.value)} 
                                                                maxLength={1} 
                                                                autoFocus={index === 0} // Auto focus on the first input
                                                            />
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>

                                            <div className="">
                                                <button type="submit" className="login_btn my-5 text-white" >Verify</button>
                                            </div>

                                            <div className="resend_otp">
                                                <p>Don’t Receive OTP? <a href="#" onClick={() => { /* Add resend OTP logic here */ }}>Resend Code</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConfermMobile