import { Link } from "react-router-dom"
import { useTheme } from "../../containers";

const EmergencyServices =() =>
{

    const { isDarkMode } = useTheme();
    return(
        <>
           <section class="same_width_page verify_page">
        <div class="container">
            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <div class="same_color_body">
                    <div class="login_wepper">
                        <div class="same_arrow_screen">
                            <Link to="/home" className="emergencyservice">
                            
                            <img src="images/Forgot Password Menu icon.png" alt="" class="dark_arrow"/>
                            <img src="images/back-arrow-light.png" alt="" class="light_arrow"/>
                            </Link>
                            <span class="light_arow_text">Emergency Service</span>
                        </div>
                        <div class="login_inner">
                            <div class="logo">
                                <img src="images/logo.svg" alt="" class="dark_logo"/>
                                <img src="images/light-logo.svg" alt="" class="light_logo"/>
                            </div>
                            <div class="emergency_icon_weppe">
                                <div class="emergency_inner">
                                    <div class="emergency_icon_inner">
                                        <Link to="https://www.google.com/maps/search/hospital+near+me/" target="_blank">
                                        <img src="images/hospital-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-hospital-icon.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Hospital</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/police+station+near+me/" target="_blank">
                                        <img src="images/police-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Police.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Police</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/petrol+pump+near+me/" target="_blank">
                                        <img src="images/petrol-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Petrol.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Petrol</p>
                                    </div>
                                </div>
                                <div class="emergency_inner">
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/motor+bike+repairing+shop+near+me/" target="_blank">
                                        <img src="images/bike-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Bike.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Bike</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/car+repairing+shop+near+me/" target="_blank">
                                        <img src="images/car-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Car.png" alt="" class="light_arrow"/>
                                    </Link>
                                        <p>Car</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/puncture+shop+near+me/" target="_blank">
                                        <img src="images/puncture-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Puncture.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Puncture</p>
                                    </div>
                                </div>
                                <div class="emergency_inner">
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/evchargingstation+near+me/" target="_blank">
                                        <img src="images/EV-charging-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-EV-Charging.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>EV Charging</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/medical+shop+near+me/" target="_blank">
                                        <img src="images/medical-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Medical.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Medical</p>
                                    </div>
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/pet+clinic+near+me/" target="_blank">
                                        <img src="images/pet-clinic-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Pet-Clinic.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Pet Clinic</p>
                                    </div>
                                </div>
                                <div class="emergency_inner justify-content-center">
                                    <div class="emergency_icon_inner">
                                    <Link to="https://www.google.com/maps/search/parking+area+near+me/" target="_blank">
                                        <img src="images/parking-area-icon.png" alt="" class="dark_arrow"/>
                                        <img src="images/light-Parking-Area.png" alt="" class="light_arrow"/>
                                        </Link>
                                        <p>Parking Area</p>
                                    </div>

                                </div>
                            </div>
                            <div class="logo_footer">
                                <img src="images/logo_light.png" alt="" class="dark_arrow"/>
                                <img src="images/logo-light-thems.png" alt="" class="light_arrow"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </>
    )
}

export default EmergencyServices