import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../containers';
import axios from 'axios';
import { apiurl, DAPI_URL } from '../../_config';
import toast from 'react-hot-toast';

const Parkvehicle = () => {
    const { isDarkMode } = useTheme();
    const userId = localStorage.getItem('userId');
    const [parkingDetails, setParkingDetails] = useState([]);
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lat, setLat] = useState(null);
    const [long, setLong] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchParkingDetails = async () => {
            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.GetParkingDetail}`, {
                    user_id: userId,
                });

                if (response.data.status === 1) {
                    setParkingDetails(response.data.data);
                }
            } catch (error) {
                console.error('Error fetching parking details:', error);
                toast.error("Error fetching parking details. Please try again later.");
            }
        };

        const fetchVehicleOptions = async () => {
            try {
                const response = await axios.post(`${apiurl}${DAPI_URL?.GetAllQrCodes}`, {
                    id: userId,
                });

                if (response.data.status === 1) {
                    const vehicleNumbers = response.data.data.map(vehicle => vehicle.vehicleNumber);
                    setVehicleOptions(vehicleNumbers);
                }
            } catch (error) {
                console.error('Error fetching vehicle options:', error);
                toast.error("Error fetching vehicle options. Please try again later.");
            }
        };

        fetchParkingDetails();
        fetchVehicleOptions();

        // Get the user's location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
            }, () => {
                toast.error("Unable to retrieve your location.");
            });
        } else {
            toast.error("Geolocation is not supported by this browser.");
        }
    }, [userId]);

    const handleDeleteParking = async (id) => {
        console.log("Attempting to delete parking with ID:", id); // Debug log
        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.DeleteParkingDetail}`, {
                user_id: userId,
                id: id, // Ensure the ID is included here
            });

            if (response.data.status === 1) {
                toast.success("Parked vehicle successfully deleted.");
                // Update state immediately after successful deletion
                
                setParkingDetails(prev => prev.filter(parking => parking.id !== id));
                 
            } else {
                toast.error("Failed to delete parked vehicle. Please try again.");
                navigate("/home");
            }
        } catch (error) {
            console.error('Error deleting parked vehicle:', error);
            toast.error("Error deleting parked vehicle. Please try again later.");
            navigate("/home");
        }
    };

    const toggleModal = () => {
        setIsModalOpen(prev => !prev);
    };

    const handleParkVehicle = async () => {
        if (!selectedVehicle || !lat || !long) {
            toast.error("Please select a vehicle and ensure location access.");
            return;
        }

        const vehicleData = {
            user_id: userId,
            vechicle_type: "", // Assuming this is predefined
            vehicle_number: selectedVehicle,
            time: new Date().toISOString(), // Current time in ISO format
            lat: lat.toString(),
            longi: long.toString()
        };

        try {
            const response = await axios.post(`${apiurl}${DAPI_URL?.AddParkingDetials}`, vehicleData);

            if (response.data.status === 1) {
                toast.success("Vehicle parked successfully!");
                const formattedDate = new Date(vehicleData.time).toLocaleString();
                setParkingDetails(prev => [
                    ...prev,
                    {
                        ...vehicleData,
                        created_at: formattedDate, // Use the formatted date
                    }
                ]);
            } else {
                toast.error("Failed to park vehicle. Please try again.");
            }
        } catch (error) {
            toast.error("Error while parking vehicle. Please try again later.");
        } finally {
            toggleModal();
        }
    };

    return (
        <>
            <section className="same_width_page park_vehicel_page only_dark">
                <div className="container">
                    <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                        <div className="same_color_body">
                            <div className="same_arrow_screen">
                                <Link to="/home" className="home-page">
                                    <img src="images/Forgot Password Menu icon.png" alt="Forgot Password Menu icon" className="dark_arrow" />
                                    <img src="images/back-arrow-light.png" alt="Back Arrow" className="light_arrow" />
                                </Link>
                                <span className="light_arow_text">Park Vehicle</span>
                            </div>
                            <div className="scrre_bg">
                                <div className="map_wepper">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.8908152093495!2d75.78707807600122!3d26.906960060336797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db35e704ce509%3A0x21a49e8d70a4d0!2siShivax%20-%20Best%20IT%20Company%20%7C%20Software%20Devlopment%20%7C%20Mobile%20App%20Development%20Company%20in%20India!5e0!3m2!1sen!2sin!4v1725959450083!5m2!1sen!2sin"
                                        width="100%" height="194" style={{ border: 0 }} allowFullScreen="" loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                                <div className="park_vecal_datel_wepper">
                                    {parkingDetails.map(parking => (
                                        <div key={parking.id} className="park_vecal_car_inner">
                                            <div>
                                                <p>{parking.vehicle_number}</p>
                                                <span>{parking.created_at}</span>
                                            </div>
                                            <div className="edit_wepper">
                                                <Link href="">
                                                    <img src="images/location.png" alt="View on Map" className="dark_arrow" />
                                                </Link>
                                                <a href={`https://www.google.com/maps/search/?api=1&query=${parking.lat},${parking.longi}`} target="_blank" rel="noopener noreferrer">
                                                    <img src="images/light-lightprofile-map.png" width="54" height="54" alt="" className="light_arrow" />
                                                </a>
                                                <Link href="" onClick={() => handleDeleteParking(parking.id)}>
                                                    <img src="images/park-delete.png" alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="logout_btn popup_btn_wepper">
                                    <button className="active" id="popUpModal" onClick={toggleModal}>Park My Vehicle</button>
                                </div>
                                <div className="park_map_btn">
                                    <Link to="/contactus"><img src="images/massage-icon.png" alt="Contact Us" /></Link>
                                </div>
                            </div>
                        </div>
                        {isModalOpen && (
                            <div id="popUp" className="overlay">
                                <div className="same_width_page popup_page">
                                    <div className="container">
                                        <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                                            <div>
                                                <div className="park_popup_wepper only_demo">
                                                    <div className="park_popup_inner">
                                                        <div className="closeModalButton" onClick={toggleModal}>
                                                            <i className="icon fa-solid fa-xmark"></i>
                                                        </div>
                                                        <div className="popup_content">
                                                            <h2>Select Vehicle</h2>
                                                            <div className="login_from">
                                                                <form action="">
                                                                    <div className="form-group from_chek">
                                                                        <label htmlFor="Vehicle">Select Vehicle</label>
                                                                        <div className="wrap">
                                                                            <select
                                                                                name="Vehicle"
                                                                                id="Vehicle"
                                                                                className="blood_group"
                                                                                value={selectedVehicle}
                                                                                onChange={(e) => setSelectedVehicle(e.target.value)}
                                                                            >
                                                                                <option value="">Select a vehicle</option>
                                                                                {vehicleOptions.map((vehicle, index) => (
                                                                                    <option key={index} value={vehicle}>{vehicle}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                <div>
                                                                    <button className="login_btn" onClick={handleParkVehicle}>Park</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Parkvehicle;
