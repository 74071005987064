// Splash.js

import { useTheme } from "../../../containers";

 
 

const Splash = () => {
    const { isDarkMode } = useTheme();

    return (
        <section class="same_width_page first_page_wepper">
        <div class="container">
            <div id="body" className={isDarkMode ? 'dark-mode' : 'light-mode'}>
                <div class="same_color_body">
                    <div class="logo">
                        <img src="images/logo.svg" alt="" class="dark_logo"/>
                        <img src="images/light-logo.svg" alt="" class="light_logo"/>
                    </div>
                    <div class="india_logo">
                        <img src="images/india-flag.png" alt="" class="light_logo" width="92" height="87"/>
                        <img src="images/light-india-flag.png" alt="" class="dark_logo"/>
                        <p>Powered by iShivax®</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Splash;
